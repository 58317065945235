<template>
  <div class="container input-health-condition-container">
    <div class="card card-body border-0">
      <div v-if="initialized" class="myform">
        <form class="card-form-input">
          <h1 class="page-title">{{ $t("inputHealthCondition.default.pageTitle") }}</h1>

          <div v-if="afterPost">
            <label>{{ $t("inputHealthCondition.heatStrokeMessage.message", { 'score': this.afterHeatStrokeScore } ) }}</label><br>

            <div class="mt-3" v-if="afterHeatStrokeScore <= 30">
              <label>{{ $t("inputHealthCondition.heatStrokeMessage.advice1") }}</label><br>
            </div>
            <div class="mt-3" v-else>
              <label>{{ $t("inputHealthCondition.heatStrokeMessage.advice2") }}</label><br>
            </div>
            <div>
              <img v-if="afterHeatStrokeScore <= 15" class="heat-stroke mt-5" src="../assets/images/healo-result-1.png" alt="">
              <img v-else-if="afterHeatStrokeScore <= 30" class="heat-stroke mt-5" src="../assets/images/healo-result-2.png" alt="">
              <img v-else class="heat-stroke mt-5" src="../assets/images/healo-result-3.png" alt="">
            </div>
          </div>
          <div v-else-if="isAbnormality">
            <label>{{ $t("inputHealthCondition.noData.noDays") }}</label><br>
          </div>
          <div v-else-if="!isAbnormality && inputCount === 0">
            <label>{{ $t("inputHealthCondition.noData.noDays") }}</label><br>
            <br>
            <!--
            <label v-if="$i18n.locale ==='ja'">
            ワクチン接種やPCR検査を新たに受けた方は、<router-link :to="{ name: 'AccountSettings' }">アカウント情報</router-link>を必ず更新して下さい。</label>
            <label v-else>
            If you have a new vaccination or PCR test, please be sure to update your <router-link :to="{ name: 'AccountSettings' }">account information</router-link>.</label>
            <br>
            <label>{{ $t("inputHealthCondition.noData.vacctinationCount") }}{{ $t(coronaVaccinationCount) }}</label><br>
            <label>{{ $t("inputHealthCondition.noData.vacctinationDate") }}{{ $t(latestCoronaVaccinationDate) }}</label><br>
            <label>{{ $t("inputHealthCondition.noData.pcrCount") }}{{ $t(latestPcrTestResult) }}</label><br>
            <label>{{ $t("inputHealthCondition.noData.pcrDate") }}{{ $t(latestPcrTestDate) }}</label><br>
            -->
          </div>
          <div v-else>
            <div class="form-group mt-3">
              <p>{{ $t("inputHealthCondition.default.sendCaution") }}</p>
            </div>

            <div class="form-group mt-3">
              <label class="mb-2" for="isPastInput">
                {{ $t("inputHealthCondition.default.labelDate(Japan Standard Time)") }}
              </label>
              <div class="input-group">
                <select id="isPastInput" v-model="date" aria-label="Default select example" class="form-select"
                        name="date"
                        v-bind:disabled="isPastInput"
                        @change="dateSelectionHandler">
                  <option v-if="dates.length > 0" :value="dates[0]">{{ dates[0] }}</option>
                </select>
              </div>
              <div v-if="errors['date']" id="err_date" class="form-error">
                {{ errors['date'] }}
              </div>
            </div>
            <!--
        <div class="form-group mt-3">
          <label class="mt-3 mb-2" for="bodyTemp">
            {{ $t("inputHealthCondition.default.labelBodyTemperature") }}
          </label>
          <div class="input-group">
            <select id="bodyTemp" v-model="bodyTemp" aria-label="Default select example" class="form-select"
                    name="temperature">
              <option value="39.0">{{
                  $t("inputHealthCondition.default.labelBodyTemperatureOver", {
                    temp: "39.0",
                  })
                }}
              </option>
              <option value="38.9">38.9</option>
              <option value="38.8">38.8</option>
              <option value="38.7">38.7</option>
              <option value="38.6">38.6</option>
              <option value="38.5">38.5</option>
              <option value="38.4">38.4</option>
              <option value="38.3">38.3</option>
              <option value="38.2">38.2</option>
              <option value="38.1">38.1</option>
              <option value="38.0">38.0</option>
              <option value="37.9">37.9</option>
              <option value="37.8">37.8</option>
              <option value="37.7">37.7</option>
              <option value="37.6">37.6</option>
              <option value="37.5">37.5</option>
              <option value="37.4">37.4</option>
              <option value="37.3">37.3</option>
              <option value="37.2">37.2</option>
              <option value="37.1">37.1</option>
              <option value="37.0">37.0</option>
              <option value="36.9">36.9</option>
              <option value="36.8">36.8</option>
              <option value="36.7">36.7</option>
              <option value="36.6">36.6</option>
              <option value="36.5">36.5</option>
              <option selected>----</option>
              <option value="36.4">36.4</option>
              <option value="36.3">36.3</option>
              <option value="36.2">36.2</option>
              <option value="36.1">36.1</option>
              <option value="36.0">36.0</option>
              <option value="35.9">35.9</option>
              <option value="35.8">35.8</option>
              <option value="35.7">35.7</option>
              <option value="35.6">35.6</option>
              <option value="35.5">35.5</option>
              <option value="35.4">35.4</option>
              <option value="35.3">35.3</option>
              <option value="35.2">35.2</option>
              <option value="35.1">35.1</option>
              <option value="35.0">{{
                  $t("inputHealthCondition.default.labelBodyTemperatureLess", {
                    temp: "35.0",
                  })
                }}
              </option>
            </select>
            <div class="input-group-append">
              <span id="basic-addon2" class="input-group-text">&#8451;</span>
            </div>
          </div>
          <div v-if="errors['bodyTemp']" id="err_temperature" class="form-error">{{ errors['bodyTemp'] }}</div>
        </div>

        <div v-if="isPastInput" class="form-group fw-bold">
          <p class="title-qa font-20">{{ $t("inputHealthCondition.past14Days.last14days") }}</p>
        </div>
        -->

            <!--
            <div v-for="(obj, qIndex) in questions"
                 v-bind:key="obj.questionnaireItemId" class="form-group">
              <label v-bind:class="{'mt-0' : qIndex === 0, 'mt-3' : qIndex > 0}">
                {{ obj.questionnaireTitle }}
              </label>
              <div class="mt-2">
                <div v-for="(radio, index) in obj.questionnaireChoice" class="form-check form-check-inline">
                  <input :id="getRadioId(obj, index)" v-model="questionValues[qIndex]" :value="radio.choiceValue"
                         class="form-check-input  mt-2"
                         type="radio">
                  <label :for="getRadioId(obj, index)" class="form-check-label">{{ radio.choiceTitle }}</label>
                </div>
              </div>
            </div>
            -->

            <div v-if="hasHeatStroke" class="form-group mt-3">
              <label class="mt-3">
                {{ $t("inputHealthCondition.default.physicalCondition") }}
              </label>
              <p class="font-13">{{ $t("inputHealthCondition.default.physicalConditionAnswer") }}</p>
              <div class="mt-3">
                <label class="mt-1">{{ $t("inputHealthCondition.default.tiredness") }}</label>
                <div class="form-check mt-2">
                  <input id="heatStrokeQuestionTiredness1" v-model="heatStrokeQuestionTiredness"
                         :value="Number(1)"
                         class="form-check-input mt-2"
                         type="radio">
                  <label class="form-check-label" for="heatStrokeQuestionTiredness1">{{
                      $t("inputHealthCondition.default.veryGood")
                    }}</label>
                </div>
                <div class="form-check">
                  <input id="heatStrokeQuestionTiredness2" v-model="heatStrokeQuestionTiredness"
                         :value="Number(2)"
                         class="form-check-input mt-2"
                         type="radio">
                  <label class="form-check-label" for="heatStrokeQuestionTiredness2">{{
                      $t("inputHealthCondition.default.good")
                    }}</label>
                </div>
                <div class="form-check">
                  <input id="heatStrokeQuestionTiredness3" v-model="heatStrokeQuestionTiredness"
                         :value="Number(3)"
                         class="form-check-input mt-2"
                         type="radio">
                  <label class="form-check-label" for="heatStrokeQuestionTiredness3">{{
                      $t("inputHealthCondition.default.averageUsually")
                    }}</label>
                </div>
                <div class="form-check">
                  <input id="heatStrokeQuestionTiredness4" v-model="heatStrokeQuestionTiredness"
                         :value="Number(4)"
                         class="form-check-input mt-2"
                         type="radio">
                  <label class="form-check-label" for="heatStrokeQuestionTiredness4">{{
                      $t("inputHealthCondition.default.bad")
                    }}</label>
                </div>
                <div class="form-check">
                  <input id="heatStrokeQuestionTiredness5" v-model="heatStrokeQuestionTiredness"
                         :value="Number(5)"
                         class="form-check-input mt-2"
                         type="radio">
                  <label class="form-check-label" for="heatStrokeQuestionTiredness5">{{
                      $t("inputHealthCondition.default.veryBad")
                    }}</label>
                </div>
                <div v-if="errors['heatStrokeQuestionTiredness']" class="form-error">
                  {{ errors['heatStrokeQuestionTiredness'] }}
                </div>
              </div>
              <div class="mt-3">
                <label class="mt-1">{{ $t("inputHealthCondition.default.appetite") }}</label>
                <div class="form-check mt-2">
                  <input id="heatStrokeQuestionAppetite1" v-model="heatStrokeQuestionAppetite"
                         :value="Number(1)"
                         class="form-check-input mt-2"
                         type="radio">
                  <label class="form-check-label" for="heatStrokeQuestionAppetite1">{{
                      $t("inputHealthCondition.default.veryGood")
                    }}</label>
                </div>
                <div class="form-check">
                  <input id="heatStrokeQuestionAppetite2" v-model="heatStrokeQuestionAppetite"
                         :value="Number(2)"
                         class="form-check-input mt-2"
                         type="radio">
                  <label class="form-check-label" for="heatStrokeQuestionAppetite2">{{
                      $t("inputHealthCondition.default.good")
                    }}</label>
                </div>
                <div class="form-check">
                  <input id="heatStrokeQuestionAppetite3" v-model="heatStrokeQuestionAppetite"
                         :value="Number(3)"
                         class="form-check-input mt-2"
                         type="radio">
                  <label class="form-check-label" for="heatStrokeQuestionAppetite3">{{
                      $t("inputHealthCondition.default.averageUsually")
                    }}</label>
                </div>
                <div class="form-check">
                  <input id="heatStrokeQuestionAppetite4" v-model="heatStrokeQuestionAppetite"
                         :value="Number(4)"
                         class="form-check-input mt-2"
                         type="radio">
                  <label class="form-check-label" for="heatStrokeQuestionAppetite4">{{
                      $t("inputHealthCondition.default.bad")
                    }}</label>
                </div>
                <div class="form-check">
                  <input id="heatStrokeQuestionAppetite5" v-model="heatStrokeQuestionAppetite"
                         :value="Number(5)"
                         class="form-check-input mt-2"
                         type="radio">
                  <label class="form-check-label" for="heatStrokeQuestionAppetite5">{{
                      $t("inputHealthCondition.default.veryBad")
                    }}</label>
                </div>
                <div v-if="errors['heatStrokeQuestionAppetite']" class="form-error">
                  {{ errors['heatStrokeQuestionAppetite'] }}
                </div>
              </div>
              <div class="mt-3">
                <label class="mt-1" v-html="$t('inputHealthCondition.default.sleepForQuestion')"></label>
                <div class="form-check mt-2">
                  <input id="heatStrokeQuestionSleep1" v-model="heatStrokeQuestionSleep"
                         :value="Number(1)"
                         class="form-check-input mt-2"
                         type="radio">
                  <label class="form-check-label" for="heatStrokeQuestionSleep1">{{
                      $t("inputHealthCondition.default.veryGood")
                    }}</label>
                </div>
                <div class="form-check">
                  <input id="heatStrokeQuestionSleep2" v-model="heatStrokeQuestionSleep"
                         :value="Number(2)"
                         class="form-check-input mt-2"
                         type="radio">
                  <label class="form-check-label" for="heatStrokeQuestionSleep2">{{
                      $t("inputHealthCondition.default.good")
                    }}</label>
                </div>
                <div class="form-check">
                  <input id="heatStrokeQuestionSleep3" v-model="heatStrokeQuestionSleep"
                         :value="Number(3)"
                         class="form-check-input mt-2"
                         type="radio">
                  <label class="form-check-label" for="heatStrokeQuestionSleep3">{{
                      $t("inputHealthCondition.default.averageUsually")
                    }}</label>
                </div>
                <div class="form-check">
                  <input id="heatStrokeQuestionSleep4" v-model="heatStrokeQuestionSleep"
                         :value="Number(4)"
                         class="form-check-input mt-2"
                         type="radio">
                  <label class="form-check-label" for="heatStrokeQuestionSleep4">{{
                      $t("inputHealthCondition.default.bad")
                    }}</label>
                </div>
                <div class="form-check">
                  <input id="heatStrokeQuestionSleep5" v-model="heatStrokeQuestionSleep"
                         :value="Number(5)"
                         class="form-check-input mt-2"
                         type="radio">
                  <label class="form-check-label" for="heatStrokeQuestionSleep5">{{
                      $t("inputHealthCondition.default.veryBad")
                    }}</label>
                </div>
                <div v-if="errors['heatStrokeQuestionSleep']" class="form-error">{{
                    errors['heatStrokeQuestionSleep']
                  }}
                </div>
              </div>
              <div class="mt-3">
                <label class="mt-1" v-html="$t('inputHealthCondition.default.sweatForQuestion')"></label>
                <div class="form-check mt-2">
                  <input id="heatStrokeQuestionSweat1" v-model="heatStrokeQuestionSweat"
                         :value="Number(1)"
                         class="form-check-input mt-2"
                         type="radio">
                  <label class="form-check-label" for="heatStrokeQuestionSweat1">{{
                      $t("inputHealthCondition.default.veryGood")
                    }}</label>
                </div>
                <div class="form-check">
                  <input id="heatStrokeQuestionSweat2" v-model="heatStrokeQuestionSweat"
                         :value="Number(2)"
                         class="form-check-input mt-2"
                         type="radio">
                  <label class="form-check-label" for="heatStrokeQuestionSweat2">{{
                      $t("inputHealthCondition.default.good")
                    }}</label>
                </div>
                <div class="form-check">
                  <input id="heatStrokeQuestionSweat3" v-model="heatStrokeQuestionSweat"
                         :value="Number(3)"
                         class="form-check-input mt-2"
                         type="radio">
                  <label class="form-check-label" for="heatStrokeQuestionSweat3">{{
                      $t("inputHealthCondition.default.averageUsually")
                    }}</label>
                </div>
                <div class="form-check">
                  <input id="heatStrokeQuestionSweat4" v-model="heatStrokeQuestionSweat"
                         :value="Number(4)"
                         class="form-check-input mt-2"
                         type="radio">
                  <label class="form-check-label" for="heatStrokeQuestionSweat4">{{
                      $t("inputHealthCondition.default.bad")
                    }}</label>
                </div>
                <div class="form-check">
                  <input id="heatStrokeQuestionSweat5" v-model="heatStrokeQuestionSweat"
                         :value="Number(5)"
                         class="form-check-input mt-2"
                         type="radio">
                  <label class="form-check-label" for="heatStrokeQuestionSweat5">{{
                      $t("inputHealthCondition.default.veryBadForSweat")
                    }}</label>
                </div>
                <div v-if="errors['heatStrokeQuestionSweat']" class="form-error">{{
                    errors['heatStrokeQuestionSweat']
                  }}
                </div>
              </div>
            </div>

            <div v-if="hasHeatStroke" class="form-group mt-3">
              <label class="mt-3">
                {{ $t("inputHealthCondition.default.urineColor") }}
              </label>
              <p class="font-13">{{ $t("inputHealthCondition.default.chooseUrineColor") }}</p>
              <div class="mt-3">
                <div class="form-check mt-2">
                  <input id="peeColor1" v-model="peeColor"
                         :value="Number(1)"
                         class="form-check-input mt-2"
                         type="radio">
                  <label class="form-check-label" for="peeColor1">{{
                      $t("inputHealthCondition.default.light")
                    }}
                    <span class="pee-light"></span>
                  </label>
                </div>
                <div class="form-check">
                  <input id="peeColor3" v-model="peeColor"
                         :value="Number(3)"
                         class="form-check-input mt-2"
                         type="radio">
                  <label class="form-check-label" for="peeColor3">{{
                      $t("inputHealthCondition.default.average")
                    }}
                    <span class="pee-usually"></span>
                  </label>
                </div>
                <div class="form-check">
                  <input id="peeColor5" v-model="peeColor"
                         :value="Number(5)"
                         class="form-check-input mt-2"
                         type="radio">
                  <label class="form-check-label" for="peeColor5">{{
                      $t("inputHealthCondition.default.dark")
                    }}
                    <span class="pee-dark"></span>
                  </label>
                </div>
                <div class="form-check">
                  <input id="peeColor7" v-model="peeColor"
                         :value="Number(7)"
                         class="form-check-input mt-2"
                         type="radio">
                  <label class="form-check-label" for="peeColor7">{{
                      $t("inputHealthCondition.default.veryDark")
                    }}
                    <span class="pee-very-dark"></span>
                  </label>
                </div>
                <div v-if="errors['peeColor']" class="form-error">{{ errors['peeColor'] }}</div>
              </div>
            </div>

            <div v-if="hasHeatStroke" class="form-group mt-3">
              <label class="mt-3">
                {{ $t("inputHealthCondition.default.heatstrokeHistory") }}
              </label>
              <div class="mt-3">
                <div class="form-check form-check-inline">
                  <input id="everHadHeatStroke1" v-model="everHadHeatStroke"
                         :value="Number(1)"
                         class="form-check-input mt-2"
                         type="radio">
                  <label class="form-check-label" for="everHadHeatStroke1">{{
                      $t("inputHealthCondition.default.ok")
                    }}</label>
                </div>
                <div class="form-check form-check-inline">
                  <input id="everHadHeatStroke2" v-model="everHadHeatStroke"
                         :value="Number(2)"
                         class="form-check-input mt-2"
                         type="radio">
                  <label class="form-check-label" for="everHadHeatStroke2">{{
                      $t("inputHealthCondition.default.no")
                    }}</label>
                </div>
                <div v-if="errors['everHadHeatStroke']" class="form-error">{{ errors['everHadHeatStroke'] }}</div>
              </div>
            </div>

            <div class="form-group mt-4">
              <label class="form-label" for="concerns"
                     v-html="$t('inputHealthCondition.default.concernsNoDetails')">
              </label>
              <textarea id="concerns" v-model="remarks" class="form-control" rows="5"></textarea>
              <div v-if="errors['remarks']" id="err_remarks" class="form-error">{{ errors['remarks'] }}</div>
            </div>

            <div class="form-check mt-1 custom-accept-checkbox invisible">
              <input id="flexCheckDefault" v-model="accept" class="form-check-input mt-2" type="checkbox" value="">
              <label class="form-check-label mx-2" for="flexCheckDefault">
                {{ $t("inputHealthCondition.default.noMistake") }}
              </label>
              <div v-if="errors['accept']" id="err_check" class="form-error"><br/>{{ errors['accept'] }}</div>
              <!--              <div v-if="errors['accept']" id="err_check" class="form-error"><br/>「記載内容に間違いありません」にチェックがされていません。</div>-->
            </div>
            <div class="form-check mt-1 custom-accept-checkbox">
              <button :disabled="sending" class="btn btn-primary btn-lg btn-block custom-btn" type="button"
                      v-on:click="doSubmit">{{ $t("inputHealthCondition.default.send") }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import Common from "../assets/jsipclient/common";
import {
  HealthStatusResult,
  QuestionnaireSheet,
  SetQuestionnaire,
  SetQuestionnaireSheet,
  TournamentItems
} from "../assets/jsipclient/index";

export default {
  name: "InputHealthCondition",
  data() {
    return {
      isHome: true,
      dates: [],
      date: null,
      bodyTemp: '----',
      questions: [],
      questionValues: [],
      remarks: '',
      accept: true,
      isAbnormality: false,
      afterPost: false,
      afterHeatStrokeScore: 0,
      isPastInput: false,
      inputCount: 0,
      coronaVaccinationCount: null,
      latestCoronaVaccinationDate: '',
      latestPcrTestResult: "",
      latestPcrTestDate: '',
      heatStrokeQuestionTiredness: 0,
      heatStrokeQuestionAppetite: 0,
      heatStrokeQuestionSleep: 0,
      heatStrokeQuestionSweat: 0,
      peeColor: 0,
      everHadHeatStroke: 0,
      hasHeatStroke: false,
      initialized: false,
      isCustomDateSelection: false,
      vaccinationData: {},
      pcrTestData: {},
      errors: [],
      sending: false
    }
  },
  async created() {
    await this.doGet()
    this.getUser()
  },
  watch: {
    async $route(to, from) {
      this.getUser()
      this.initialized = false
      await this.doGet()
    }
  },
  computed: {
    ...mapGetters(['config'])
  },
  /*mounted() {
    this.getUser()
  },*/
  methods: {
    async getUser() {
      const user_id = this.config.userId
      const token = this.config.token
      const api = Common.createUserApi(token)
      const jsonObject = await api.usersUserIdGetAsync(user_id)
      this.vaccinationData = {
        coronaVaccinationCount: jsonObject.coronaVaccinationCount,
        latestCoronaVaccinationDate: jsonObject.latestCoronaVaccinationDate
      }
      this.pcrTestData = {
        latestPcrTestResult: jsonObject.latestPcrTestResult,
        latestPcrTestDate: jsonObject.latestPcrTestDate
      }
      this.coronaVaccinationCount = this.getHasCoronaVaccination(jsonObject.coronaVaccinationCount)
      if(jsonObject.coronaVaccinationCount!=0){
        this.latestCoronaVaccinationDate = jsonObject.latestCoronaVaccinationDate
      }else{
        this.latestCoronaVaccinationDate = null
      }
      this.latestPcrTestResult = this.getLatestPcrTestResult(jsonObject.latestPcrTestResult)
      if(jsonObject.latestPcrTestResult!=0){
        this.latestPcrTestDate = jsonObject.latestPcrTestDate
      }else{
        this.latestPcrTestDate = null
      }
    },
    getHasCoronaVaccination(coronaVaccinationCount) {
      switch(coronaVaccinationCount) {
        case 0:
          return this.$t('account.editWithoutPassword.none')
        case 1:
          return this.$t('account.editWithoutPassword.once')
        case 2:
          return this.$t('account.editWithoutPassword.twice')
        case 3:
          return this.$t('account.editWithoutPassword.thirdTime')
        case 4:
          return this.$t('account.editWithoutPassword.fourthTime')
        case 5:
          return this.$t('account.editWithoutPassword.fifthTime')
        case 6:
          return this.$t('account.editWithoutPassword.sixthTime')
        case 7:
          return this.$t('account.editWithoutPassword.seventhTime')
        case 8:
          return this.$t('account.editWithoutPassword.eightthTime')
        case 9:
          return this.$t('account.editWithoutPassword.ninethTime')
        case 10:
          return this.$t('account.editWithoutPassword.tenthTime')
      }
    },
    getLatestPcrTestResult(getLatestPcrTestResult) {
      switch (getLatestPcrTestResult) {
        case -1:
          return this.$t('account.editWithoutPassword.negative')
        case 0:
          return this.$t('account.editWithoutPassword.notInspected')
        case 1:
          return this.$t('account.editWithoutPassword.positive')
      }
    },
    dateSelectionHandler() {
      this.isCustomDateSelection = true
      this.doGet()
    },
    async doGet() {
      this.errors = [];

      try {
        const user_id = this.$store.getters.config.userId
        const token = this.$store.getters.config.token

        const api = Common.createUserApi(token)

        const jsonObjectNoInput = await api.usersUserIdNoInputGetAsync(user_id)
        this.dates = jsonObjectNoInput.dates.map(($date) => {
          return Common.dateFormatConvertJST($date)
        })
        this.inputCount = this.dates.length
        if (this.dates.length === 0 || !Common.isToday(this.dates[0])) {
          // Empty
          this.inputCount = 0
          this.initialized = true
          return
        }
        if (!this.isCustomDateSelection) {
          this.date = this.dates[0]
        }

        const jsonObject = await api.usersUserIdQuestionnaireGetAsync(user_id, this.date)
        const result = QuestionnaireSheet.createFromJson(jsonObject)

        this.bodyTemp = "----"
        this.isPastInput = result.isPastInput
        this.hasHeatStroke = result.hasHeatStroke
        this.questions = []
        this.remarks = ''
        this.questionValues = []
        result.items.forEach((item) => {
          this.questions.push(item)
          this.questionValues.push('N')
        })
        this.isCustomDateSelection = false
        this.initialized = true
      } catch (error) {
        Common.getErrorList(error.response, this.errors)
        const isTokenError = Common.isTokenError(error.response)
        if (isTokenError) {
          await this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(error)
        }
      }
    },
    getDateFormat(e) {
      this.date = moment(e.target.value).format('YYYY/MM/DD')
    },
    async doSubmit() {
      this.errors = []

      let requestData = new SetQuestionnaireSheet()
      requestData.isPastInput = this.isPastInput
      requestData.date = this.date
      //requestData.bodyTemp = this.bodyTemp
      requestData.bodyTemp = "36.0" // Dummy
      requestData.remarks = this.remarks
      requestData.accept = true // Always true
      requestData.items = this.questionValues.map((value, index) => {
        let questionnaire = new SetQuestionnaire()
        questionnaire.questionnaireItemId = this.questions[index].questionnaireItemId
        questionnaire.questionnaireValue = value
        return questionnaire
      });
      requestData.heatStrokeQuestionTiredness = this.heatStrokeQuestionTiredness
      requestData.heatStrokeQuestionAppetite = this.heatStrokeQuestionAppetite
      requestData.heatStrokeQuestionSleep = this.heatStrokeQuestionSleep
      requestData.heatStrokeQuestionSweat = this.heatStrokeQuestionSweat
      requestData.peeColor = this.peeColor
      requestData.everHadHeatStroke = this.everHadHeatStroke
      requestData.updateHeatStroke = this.hasHeatStroke

      const user_id = this.$store.getters.config.userId
      const token = this.$store.getters.config.token
      const api = Common.createUserApi(token)

      this.sending = true
      try {
        let jsonObject = await api.usersUserIdQuestionnairePostAsync(user_id, requestData)
        let result = HealthStatusResult.createFromJson(jsonObject);

        // if (result.noInput.numberOfDays === 0) {
        //   const message = this.$t("inputHealthCondition.default.toastSuccessSend")
        //   Common.showToast(message)
        // } else {
        //   const message = this.$t("inputHealthCondition.default.toastSuccessSendNotEntered")
        //   Common.showToast(message)
        // }
        const message = this.$t("inputHealthCondition.default.toastSuccessSend")
        Common.showToast(message)


        this.afterPost = true
        this.afterHeatStrokeScore = result.heatStrokeScore

        // if (result.healthStatus === -1) {
        //   this.isAbnormality = true
        // } else {
        //   await this.doGet()
        //   this.scrollTop()
        // }
      } catch (error) {
        Common.getErrorList(error.response, this.errors)
        this.scrollTop()
        const isTokenError = Common.isTokenError(error.response)
        if (isTokenError) {
          await this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(error)
        }
      } finally {
        this.sending = false
      }
    },
    doConfirm() {
      this.isAbnormality = false
      this.afterPost = false;
      this.doGet();
    },
    getRadioId(obj, index) {
      return "radio" + obj.questionnaireItemId + "_" + index
    },
    scrollTop: function () {
      window.scrollTo({
        top: 0,
        behavior: "instant"
      });
    },
  },
  mounted() {
    window.scrollTo({top: 0, behavior: "auto"})
  }
}

</script>

<style lang="scss" scoped>
.input-health-condition-container {
  padding-left: 0;
  padding-right: 0;
}

img.heat-stroke {
  max-width: 400px;
  width: 100%;
  height: auto;
}

.pee-light {
  width: 120px;
  height: 20px;
  margin-left: 53px;
  display: inline-block;
  vertical-align: sub;
  background: #FDEBB0;
}

.pee-usually {
  width: 120px;
  height: 20px;
  margin-left: 32px;
  display: inline-block;
  vertical-align: sub;
  background: #F5E383;
}

.pee-dark {
  width: 120px;
  height: 20px;
  margin-left: 56px;
  display: inline-block;
  vertical-align: sub;
  background: #EEBA4F;
}

.pee-very-dark {
  width: 120px;
  height: 20px;
  margin-left: 20px;
  display: inline-block;
  vertical-align: sub;
  background: #D0933D;
}

.card {
  margin-bottom: 111px;
}

.card-form-input {
  max-width: 720px;
  margin: 0 auto;
}

.card-form-input > h1 {
  color: #333333;
  line-height: 66px;
  font-size: 24px;
  font-weight: bold;
}

input[type="date"] {
  background-color: #D9E5F7;
  height: 48px;
}

select {
  height: 48px;
}

.input-group-image {
  display: flex;
}

.input-group-text {
  height: 48px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group {
  height: 48px;
}

label {
  color: #333333;
  line-height: 30px;
  font-weight: 500;
}

.form-check-inline:first-child {
  margin-right: 60px;
}

textarea {
  background: #D9E5F7;
}

.custom-accept-checkbox {
  text-align: center;
  padding: 10px 0;
}

.custom-accept-checkbox input {
  float: none;
}

.custom-btn {
  background: #2663D1;
  margin: 0 auto;
  color: #FFFFFF;
  font-size: 16px;
  line-height: 24px;
  height: 44px;
  width: 350px;
}

.form-control {
  height: auto !important;
}

.warning-label {
  color: #DC3545;
}

.datepicker {
  width: 43px;
  padding: 0 !important;
  height: 48px !important;
  display: flex !important;
  align-items: center !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 0 !important;
  color: #212529 !important;
  text-align: center !important;
  white-space: nowrap !important;
  background-color: #e9ecef !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  right: 0;
  top: 16px;
  width: 20px;
  height: 20px;
  color: #9EB8E7;
  opacity: 1;
  text-align: center;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar" viewBox="0 0 20 20"><path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/></svg>') no-repeat;
}

.page-title {
  font-size: 30px !important;
}

.title-qa {
  margin-top: 60px !important;
}

.warning-button {
  background: #2663D1;
  margin: 0 auto;
  color: #FFFFFF;
  font-size: 16px;
  line-height: 24px;
  height: 44px;
  width: 350px;
  max-width: 350px !important;
}

@media only screen and (max-width: 576px) {

  .page-title {
    font-size: 24px !important;
  }
  .title-qa {
    margin-top: 50px !important;
  }
  .myform {
    font-weight: bold;
  }

  .form-check-inline {
    display: inline-block;
  }

  .form-check-inline label {
    padding-left: 10px;
  }

  .custom-btn {
    width: 250px;
  }

  .warning-button {
    width: 280px !important;
  }

}

</style>
